import Vue from "vue";
import { endPoint } from "../ds";
import {
  getOrderStatusOptions,
  getOrderLineOptions,
  getRoundtLogisticTypeOptions,
  getRoundtWarehouseOptions,
} from "../helpers/options";

const initialFilters = () => {
  return {
    keyword: "",
    statusId: "",
    productTypeId: "",
    productLineId: "",

    warehouseId: "",

    // pin filters
    producerId: null,
    productId: null,
    orderId: null,
    islandId: null,

    options: {
      status: [],
      productLine: [],
      productType: [],
      warehouse: [],
    },
  };
};

const initialState = () => {
  return {
    round: null,
    view: "overview",
    filters: initialFilters(),
  };
};

const availableViews = ["overview", "ddmp", "wh", "ddmi", "orders", "incident"];

export default {
  namespaced: true,

  state() {
    return initialState();
  },

  mutations: {
    setRound(state, round) {
      state.round = round;
    },

    setView(state, view) {
      if (availableViews.includes(view)) {
        state.view = view;
      } else {
        console.warn(`view "${view}" not available`);
      }
    },

    updateKeyword(state, value) {
      state.filters.keyword = value;
    },

    updateStatusId(state, value) {
      state.filters.statusId = value;
    },

    updateProductTypeId(state, value) {
      state.filters.productTypeId = value;
    },

    updateProductLineId(state, value) {
      state.filters.productLineId = value;
    },

    updateWarehouseId(state, value) {
      state.filters.warehouseId = value;
    },

    // pin filters
    updateProducerId(state, value) {
      state.filters.producerId = value;
    },

    updateProductId(state, value) {
      state.filters.productId = value;
    },

    updateOrderId(state, value) {
      state.filters.orderId = value;
    },

    updateIslandId(state, value) {
      state.filters.islandId = value;
    },

    setFilterOptions(state, { key, options }) {
      state.filters.options[key] = options;
    },

    resetMainFilters(state) {
      state.filters.keyword = "";
      state.filters.statusId = "";
      state.filters.productTypeId = "";
      state.filters.productLineId = "";
      state.filters.warehouseId = "";
    },

    resetPinFilter(state, filters = []) {
      for (const filter of filters) {
        state.filters[filter] = null;
      }
    },

    resetPinFilters(state) {
      const filters = ["producerId", "productId", "orderId", "islandId"];
      for (const filter of filters) {
        state.filters[filter] = null;
      }
    },

    resetState(state) {
      Object.assign(state, initialState());
    },

    resetFilters(state) {
      state.filters = initialFilters();
    },
  },

  actions: {
    initFilters({ commit }, { roleId, roundId, lang }) {
      commit("resetFilters");
      commit("setFilterOptions", {
        key: "status",
        options: [...getOrderStatusOptions()],
      });
      commit("setFilterOptions", {
        key: "productLine",
        options: [...getOrderLineOptions()],
      });

      return new Promise((resolve) => {
        Promise.all([
          getRoundtLogisticTypeOptions({ roleId, roundId, lang }),
          getRoundtWarehouseOptions({ roleId, roundId }),
        ]).then((values) => {
          commit("setFilterOptions", {
            key: "productType",
            options: [...values[0]],
          });
          commit("setFilterOptions", {
            key: "warehouse",
            options: [...values[1]],
          });
          resolve();
        });
      });
    },

    updateStatusBatch(context, formData) {
      const url = endPoint("role_orders_manager");
      return Vue.axios.patch(url, formData);
    },
  },

  getters: {
    havePinFilters(state) {
      return (
        state.filters.producerId !== null ||
        state.filters.productId !== null ||
        state.filters.orderId !== null ||
        state.filters.islandId !== null
      );
    },

    pinFilters(state) {
      const { producerId, productId, orderId, islandId } = state.filters;
      return {
        producerId,
        productId,
        orderId,
        islandId,
      };
    },
  },
};
