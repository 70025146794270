import { Query, Predicate } from "@syncfusion/ej2-data";
import { GetDataManager, GetDataManagerNew } from "../ds";
import GetOptions from "../common/form/options";
import { getTranslation } from "../helpers/common";
import {
  ORDER_STATUS,
  LINES,
  INCIDENT_ACTIONS_BATCH,
  INVOICE_TYPE,
  FAMILY_TYPE,
  ROLE_TYPE,
} from "../config/global";
import get from "lodash/get";

function getYesNoOptions() {
  return [
    { value: true, label: "si" },
    { value: false, label: "no" },
  ];
}

async function getMacroCategoryOptions(params) {
  const { lang } = params;
  const options = await GetOptions({
    dm: GetDataManager("structure_ptype"),
    query_cache: "structure_ptype",
    cur_lang: lang,
    reducer: (map, obj) => {
      map.push({
        value: String(obj.id),
        label: obj.translations[lang].name,
      });
      return map;
    },
  });
  return options;
}

async function getParentCategoryOptions(params) {
  const { category__macrocategory_id, lang } = params;
  const options = await GetOptions({
    dm: GetDataManager("structure_category_browser"),
    query: new Query().where(
      new Predicate("parent_id", "equal", null).and(
        "macrocategory_id",
        "equal",
        category__macrocategory_id
      )
    ),
    query_cache: `structure_category_browser_M${category__macrocategory_id}`,
    cur_lang: lang,
  });
  return options;
}

async function getCategoryOptions(params) {
  const { category__macrocategory_id, category__parent_id, lang } = params;
  const options = await GetOptions({
    dm: GetDataManager("structure_category_browser"),
    query: new Query().where(
      new Predicate("parent_id", "equal", category__parent_id)
        .and("macrocategory_id", "equal", category__macrocategory_id)
        .and("parent_id", "greaterthan", 0)
    ),
    query_cache: `structure_category_browser_M${category__macrocategory_id}_P${category__parent_id}`,
    cur_lang: lang,
  });

  return options;
}

async function getAgeClassOptions(params) {
  const { lang } = params;
  const options = await GetOptions({
    dm: GetDataManager("structure_ageclass"),
    query_cache: "structure_ageclass",
    cur_lang: lang,
  });
  return options;
}

async function getLogisticTypeOptions(params) {
  const { category__macrocategory_id, lang } = params;
  const query = new Query();
  if (category__macrocategory_id) {
    query.where("macrocategory_id", "equal", category__macrocategory_id);
  }
  const options = await GetOptions({
    dm: GetDataManager("structure_ltype"),
    query: query,
    query_cache: `structure_ltype_${category__macrocategory_id || ""}`,
    reducer: params.reducer || undefined,
    cur_lang: lang,
  });
  return options;
}

async function getCertificationsOptions() {
  const options = await GetOptions({
    dm: GetDataManager("structure_certification"),
    reducer_cache: "certifications_list",
    reducer: function (map, obj) {
      map.push({
        value: obj.id,
        label: obj.name,
        macro_id: obj.macrocategory_id,
      });
      return map;
    },
  });
  return options;
}

async function getCertificationsMacrocategoriesOptions() {
  const options = await GetOptions({
    dm: GetDataManager("structure_certification_macro"),
    reducer_cache: "certifications_macro",
  });
  return options;
}

async function getProductionTypeOptions(params) {
  const { lang } = params;
  const options = await GetOptions({
    dm: GetDataManager("structure_production"),
    query_cache: "structure_production",
    reducer_cache: "structure_production_need_nation",
    reducer: function (map, obj) {
      map.push({
        value: obj.id,
        label: obj.translations[lang].name,
        need_nation: obj.need_nation,
      });
      return map;
    },
  });
  return options;
}

async function getProductionTypeNationsOptions() {
  const options = await GetOptions({
    dm: GetDataManager("geo_country"),
    reducer: function (map, obj) {
      map.push({
        value: obj.code,
        label: obj.name,
      });
      return map;
    },
    reducer_cache: "geo_country",
  });
  return options;
}

async function getTaxIdOptions(params) {
  const { country_id } = params;
  const options = await GetOptions({
    dm: GetDataManager("geo_tax"),
    query: new Query().where("country_id", "equal", country_id),
    query_cache: "geo_tax_" + country_id,
  });
  return options;
}

function getPwOptions(i18n) {
  return [
    {
      value: 1,
      label: i18n.t('models.lot.pwoptions.o1.label'),
      singular: i18n.t('models.lot.pwoptions.o1.singular'),
      plural: i18n.t('models.lot.pwoptions.o1.plural'),
      short: i18n.t('models.lot.pwoptions.o1.short'),
    },
    {
      value: 2,
      label: i18n.t('models.lot.pwoptions.o2.label'),
      singular: i18n.t('models.lot.pwoptions.o2.singular'),
      plural: i18n.t('models.lot.pwoptions.o2.plural'),
      short: i18n.t('models.lot.pwoptions.o2.short'),
    },
    {
      value: 3,
      label: i18n.t('models.lot.pwoptions.o3.label'),
      singular: "",
      plural: "",
    },
  ];
}

function getLastMinTOptions(i18n) {
  return [
    {
      value: "d",
      label: i18n.t('models.productproducer.duration.days'),
    },
    {
      value: "m",
      label: i18n.t('models.productproducer.duration.months'),
    },
    {
      value: "y",
      label: i18n.t('models.productproducer.duration.years'),
    },
  ];
}

function getPriceUnitOptions(i18n) {
  return [
    { value: 1, label: "Kg" },
    { value: 2, label: "gr" },
    { value: 3, label: "L" },
    { value: 4, label: "dl" },
    { value: 5, label: "cl" },
    { value: 6, label: "ml" },
    { value: 7, label: i18n.t('models.lot.priceUnitOptions.o7') },
    { value: 8, label: i18n.t('models.lot.priceUnitOptions.o8') },
  ];
}

function getAvailabilityLimitlessOptions() {
  return [
    {
      value: true,
      label: "Illimitata",
    },
    {
      value: false,
      label: "Limitata",
    },
  ];
}

function getAvailabilityXdOptions() {
  return [
    {
      value: true,
      label: "a consegna",
    },
    {
      value: false,
      label: "in totale",
    },
  ];
}

function getValidLimitedOptions() {
  return [
    {
      value: "always",
      label: "Tutto l' anno",
    },
    {
      value: "limited",
      label: "Limitato",
    },
  ];
}

function getIslandOptions() {
  return [
    {
      value: 1,
      label: "Aperta al pubblico",
    },
    {
      value: 2,
      label: "Privata",
    },
    {
      value: 3,
      label: "A domicilio",
    },
  ];
}

async function getProducerCategoryTree(params) {
  const { lang, roleId } = params;
  const dm = GetDataManagerNew("role_producer_categorytree", [roleId]);
  const q = new Query();

  const data = await dm.executeQuery(q).then((response) => response.result);
  const categories = [];
  data.forEach((macro) => {
    const macroName = getTranslation(macro.translations, lang);
    macro.categories.forEach((parent) => {
      const parentName = getTranslation(parent.translations, lang);
      const optgroup = {
        label: `${macroName} - ${parentName}`,
        options: parent.children.map((c) => {
          return {
            value: c.id,
            label: getTranslation(c.translations, lang),
          };
        }),
      };
      categories.push(optgroup);
    });
  });
  return categories;
}

async function getProducerTypeProducts(params) {
  const { lang, roleId } = params;
  const dm = GetDataManagerNew("role_producer_typeproduct", [roleId]);
  const q = new Query();
  const data = await dm.executeQuery(q).then((response) => response.result);
  const options = data.map((option) => {
    return {
      value: option.id,
      label: getTranslation(option.translations, lang),
    };
  });
  return options;
}

async function getMarketCategoryTree(params) {
  const { familyId, marketId, lang } = params;
  const dm = GetDataManagerNew("family_market_categories", [
    familyId,
    marketId,
  ]);
  const q = new Query();

  const data = await dm.executeQuery(q).then((response) => response.result);
  const categories = [];
  data.forEach((macro) => {
    const macroName = getTranslation(macro.translations, lang);
    macro.categories.forEach((parent) => {
      const parentName = getTranslation(parent.translations, lang);
      const optgroup = {
        label: `${macroName} - ${parentName}`,
        options: parent.children.map((c) => {
          return {
            value: c.id,
            label: getTranslation(c.translations, lang),
          };
        }),
      };
      categories.push(optgroup);
    });
  });
  return categories;
}

async function getMarketProducersOptions(params) {
  const { familyId, marketId } = params;
  const dm = GetDataManagerNew("family_market_producers", [familyId, marketId]);
  const q = new Query();
  const data = await dm.executeQuery(q).then((response) => response.result);
  const options = data.map((option) => {
    return {
      value: option.id,
      label: option.role.name,
    };
  });
  return options;
}

function productDietOptions(i18n) {
  return [
    {
      value: "gluten_free",
      label: i18n.t('models.productproducer.diet.gluten_free'),
    },
    {
      value: "vegan",
      label: i18n.t('models.productproducer.diet.vegan'),
    },
    {
      value: "vegetarian",
      label: i18n.t('models.productproducer.diet.veg'),
    },
    {
      value: "lactose_free",
      label: i18n.t('models.productproducer.diet.lactose_free'),
    },
  ];
}

async function getRoundtWarehouseOptions(params) {
  const { roleId, roundId } = params;
  const query = new Query();
  const options = await GetOptions({
    dm: GetDataManagerNew("role_market_orderswh", [roleId, roundId]),
    reducer: function (map, obj) {
      map.push({
        value: obj.id,
        label: obj.wh.address.to,
      });
      return map;
    },
    query: query,
    query_cache: `structure_round_warehouses_${roleId || ""}_${roundId || ""}`,
  });
  return options;
}

async function getRoundtLogisticTypeOptions(params) {
  const { roleId, roundId, lang } = params;
  const query = new Query();
  const options = await GetOptions({
    dm: GetDataManagerNew("role_market_orders_tp", [roleId, roundId]),
    reducer: function (map, obj) {
      map.push({
        value: obj.id,
        label: get(obj, `translations.${lang}.name`, ""),
      });
      return map;
    },
    query: query,
    query_cache: `structure_round_tp_${roleId || ""}_${roundId || ""}`,
  });
  return options;
}

function getOrderStatusOptions() {
  return [
    {
      value: ORDER_STATUS.CREATE,
      label: "Confermato",
    },
    {
      value: ORDER_STATUS.PAYMENT_WAIT,
      label: "In attesa di pagamento",
    },
    {
      value: ORDER_STATUS.APPROVAL,
      label: "Approvazione",
    },
    {
      value: ORDER_STATUS.CANCELED,
      label: "Annullato",
    },
    {
      value: ORDER_STATUS.ALERT,
      label: "Segnalato",
    },
    {
      value: ORDER_STATUS.PRODUCER_IN_CHARGE,
      label: "Produttore",
    },
    {
      value: ORDER_STATUS.MARKET_ARRIVED,
      label: "Consegato al magazzino",
    },
    {
      value: ORDER_STATUS.MARKET_STOCK,
      label: "In stock presso il magazzino",
    },
    {
      value: ORDER_STATUS.MARKET_IN_CHARGE,
      label: "Pronto",
    },
    {
      value: ORDER_STATUS.ISLAND_ARRIVED,
      label: "Pronto per la consegna",
    },
    {
      value: ORDER_STATUS.ISLAND_IN_CHARGE,
      label: "Ritiro",
    },
    {
      value: ORDER_STATUS.FAMILY_IN_CHARGE,
      label: "Ritirato",
    },
    {
      value: ORDER_STATUS.DELIVERED,
      label: "Completato",
    },
    {
      value: ORDER_STATUS.PAID,
      label: "Pagato",
    },
    {
      value: ORDER_STATUS.ERROR,
      label: "Errore",
    },
  ];
}

function getOrderLineOptions() {
  return [
    {
      value: LINES.OTHER,
      label: "Altro",
    },
    {
      value: LINES.BOX,
      label: "Box",
    },
    {
      value: LINES.FRIDGE,
      label: "Frigo",
    },
    {
      value: LINES.SHIP,
      label: "Spedizione",
    },
  ];
}
function getIncidentBatchActionOptions() {
  return [
    { value: INCIDENT_ACTIONS_BATCH.CANCELLED, label: "Annullamento" },
    {
      value: INCIDENT_ACTIONS_BATCH.CANCELLED_BY_PRODUCER,
      label: "Annulamento a carico produttore",
    },
    {
      value: INCIDENT_ACTIONS_BATCH.CANCELLED_BY_MARKET,
      label: "Annulamento a carico mercato",
    },
    {
      value: INCIDENT_ACTIONS_BATCH.CANCELLED_BY_ISLAND,
      label: "Annulamento a carico isola",
    },
    {
      value: INCIDENT_ACTIONS_BATCH.REMOVE_FROM_INCIDENT,
      label: "Rimuovi da segnalazione",
    },
  ];
}

function getInvoiceTypeOptions() {
  return [
    { value: INVOICE_TYPE.CUSTOMER, label: "Cliente" },
    { value: INVOICE_TYPE.MARKET, label: "Mercato" },
    { value: INVOICE_TYPE.PRODUCER, label: "Produttore" },
    { value: INVOICE_TYPE.ISLAND, label: "Isola" },
  ];
}

function getFamilyTypeOptions(app) {
  return [
    { value: FAMILY_TYPE.PRIVATE, label: app.$t('options.familytype.private')},
    { value: FAMILY_TYPE.SOCIETY, label: app.$t('options.familytype.society') },
    { value: FAMILY_TYPE.COOP, label: app.$t('options.familytype.coop') },
  ];
}

function getRoleTypeOptions(app) {
  return [
    { value: ROLE_TYPE.MARKET, label: app.$t('options.roletype.market')},
    { value: ROLE_TYPE.PRODUCER, label: app.$t('options.roletype.producer') },
    { value: ROLE_TYPE.PRODUCERISLAND, label: app.$t('options.roletype.producerisland') },
    { value: ROLE_TYPE.ISLAND, label: app.$t('options.roletype.island') },
    { value: ROLE_TYPE.SHOP, label: app.$t('options.roletype.shop') },
  ];
}

async function getNationsActiveOptions() {
  const options = await GetOptions({
    dm: GetDataManager("geo_country"),
    query: new Query().where("status", "equal", 100),
    reducer: function (map, obj) {
      map.push({ value: obj.code, label: obj.name });
      return map;
    },
    reducer_cache: "geo_country_active",
  });
  return options;
}

async function getPostalCodeOptions(params) {
  const { countryId, search } = params;

  if (countryId) {
    let predicate = new Predicate("country_id", "equal", countryId);
    if (search) {
      predicate = predicate.and("code", "contains", search);
    }
    const query = new Query().where(predicate).take(10);
    const options = await GetOptions({
      dm: GetDataManager("geo_postalcode"),
      query: query,
      reducer: function (map, obj) {
        //map.push({ value: obj.id, label: obj.code });
        map.push(obj);
        return map;
      },
      reducer_cache: `structure_postalcode_${countryId}_${search || ""}`,
    });
    return options;
  }
  return [];
}

export {
  getYesNoOptions,
  getMacroCategoryOptions,
  getParentCategoryOptions,
  getCategoryOptions,
  getAgeClassOptions,
  getLogisticTypeOptions,
  getCertificationsOptions,
  getCertificationsMacrocategoriesOptions,
  getProductionTypeOptions,
  getProductionTypeNationsOptions,
  getTaxIdOptions,
  getPwOptions,
  getLastMinTOptions,
  getPriceUnitOptions,
  getAvailabilityLimitlessOptions,
  getAvailabilityXdOptions,
  getValidLimitedOptions,
  getIslandOptions,
  getProducerCategoryTree,
  getProducerTypeProducts,
  getMarketCategoryTree,
  getMarketProducersOptions,
  productDietOptions,
  getRoundtWarehouseOptions,
  getRoundtLogisticTypeOptions,
  getOrderStatusOptions,
  getOrderLineOptions,
  getIncidentBatchActionOptions,
  getInvoiceTypeOptions,
  getFamilyTypeOptions,
  getRoleTypeOptions,
  getNationsActiveOptions,
  getPostalCodeOptions,
};
