import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

function loadView(view) {
  return () => import(`../${view}.vue`);
}

Vue.router = new Router({
  mode: "history", // https://router.vuejs.org/api/#mode
  linkActiveClass: "open active",
  scrollBehavior: () => ({
    y: 0,
  }),
  routes: [
    {
      // Home utente
      path: "/",
      redirect: "/dashboard",
      name: "Home",
      component: loadView("containers/UiContainer"),
      // component: loadView("containers/TheContainer"),
      meta: {
        auth: true,
      },
      children: [
        {
          //dashboard utente
          path: "dashboard",
          name: "UserDashboard",
          component: loadView("views/user/Dashboard"),
        },
        {
          path: "test",
          name: "Test",
          component: loadView("views/test"),
        },
        {
          //Nuovo ruolo operativo
          path: "newrole",
          name: "UserNewRole",
          component: loadView("views/user/NewRole"),
        },
        {
          path: "collaborate/new-producer",
          name: "UserCollaborateNewProducer",
          component: loadView("views/user/collaborate/NewProducer"),
        },
        {
          path: "/shop/:familyId/carts",
          name: "UserMarketCarts",
          component: loadView("views/user/UiMarketCarts"),
        },
        {
          path: "/shop/:familyId/invoices",
          name: "UserMarketInvoices",
          component: loadView("views/user/UiMarketInvoices"),
        },
        {
          path: "/shop/:familyId/carts/:marketId",
          name: "UserMarketCartDetail",
          component: loadView("views/user/UiMarketCartDetail"),
        },
        {
          path: "/shop/:familyId/markets",
          name: "UserMarketList",
          component: loadView("views/user/UiMarkets"),
          children: [
            {
              path: "/shop/:familyId/markets/:marketId",
              name: "UserMarketDetail",
              component: loadView("views/user/UiMarketShowcase"),
              children: [
                {
                  path: "/shop/:familyId/markets/:marketId/:productId",
                  name: "UserMarketProduct",
                  component: loadView("views/user/UiMarketProduct"),
                },
              ],
            },
          ],
        },
        {
          path: "/shop/:familyId/orders",
          name: "UserMarketOrders",
          component: loadView("views/user/UiMarketOrders"),
        },
        {
          path: "/shop/:familyId/orders/:orderId",
          name: "UserMarketOrderDetail",
          component: loadView("views/user/UiMarketOrderDetail"),
        },
        {
          //vetrine utente
          path: "orders",
          name: "user.sidebar.orders",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          redirect: "/orders/current",
          children: [
            {
              //ordini in corso
              path: "current",
              // name: "user.sidebar.orders_current",
              name: "UserOrdersInProgress",
              component: loadView("views/uc"),
            },
            {
              //archivio ordini
              path: "archive",
              // name: "user.sidebar.orders_archive",
              name: "UserOrdersArchive",
              component: loadView("views/user/OrdersArchive"),
            },
            {
              //Abbonamenti
              path: "subcription",
              // name: "user.sidebar.orders_subscription",
              name: "UserOrdersSubscription",
              component: loadView("views/uc"),
            },
          ],
        },
        {
          //casse
          path: "wallet",
          name: "user.sidebar.wallet",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          redirect: "/wallet/moves",
          children: [
            {
              //movimenti cassa
              path: "moves",
              name: "user.sidebar.wallet_moves",
              component: loadView("views/uc"),
            },
            {
              //Fatture
              path: "invoices",
              name: "user.sidebar.wallet_invoices",
              component: loadView("views/uc"),
            },
            {
              //Ricariche
              path: "charge",
              name: "user.sidebar.wallet_recharge",
              component: loadView("views/uc"),
            },
            {
              //Transazioni
              path: "transactions",
              name: "user.sidebar.wallet_transactions",
              component: loadView("views/uc"),
            },
          ],
        },
        {
          //vetrine utente
          path: "user",
          // name: 'user.sidebar.profile',
          redirect: "/user/profile",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              //Profilo utente
              path: "profile",
              name: "header.account.profile",
              component: loadView("views/user/ProfileData"),
            },
            {
              //account social
              path: "social",
              name: "header.account.social",
              component: loadView("views/user/social/SocialAccount"),
            },
            {
              //gestione email
              path: "email",
              name: "header.account.mail",
              component: loadView("views/user/email/Email"),
            },
            {
              //Gestione Famiglie
              path: "families",
              name: "header.account.family",
              component: loadView("views/user/families/Family"),
            },
            {
              path: "settings",
              name: "UserSettings",
              component: loadView("views/user/settings/Settings"),
            },
          ],
        },
      ],
    },
    {
      path: "/role/:id/products",
      name: "RoleProductsContainer",
      component: loadView("containers/UiProductContainer"),
      children: [
        {
          path: "create",
          name: "RoleProductsCreate",
          component: loadView("views/role/products/ProductCreateForm"),
        },
        {
          path: "edit/:product_id",
          name: "RoleProductsEdit",
          component: loadView("views/role/products/ProductCreateForm"),
        },
        {
          path: "edit-prices",
          name: "RoleProductsEditPrices",
          component: loadView(
            "views/role/products/ProductsTableListEditPrices"
          ),
        },
        {
          path: "edit-availability",
          name: "RoleProductsEditAvailability",
          component: loadView(
            "views/role/products/ProductsTableListEditAvailability"
          ),
        },
      ],
    },
    {
      path: "/role/:id/products-list",
      component: loadView("containers/UiContainer"),
      children: [
        {
          path: "",
          name: "RoleProductsList",
          component: loadView("views/role/products/Products"),
        },
      ],
    },
    {
      path: "/role/:id/orders/rounds/list",
      component: loadView("containers/UiContainer"),
      children: [
        {
          path: "",
          name: "RoleOrdersRoundsList",
          component: loadView("views/role/orders/rounds/OrdersRoundsList"),
        },
      ],
    },
    {
      path: "/role/:id/orders",
      name: "RoleOrdersContainer",
      component: loadView("containers/UiOrdersContainer"),
      children: [
        {
          path: "manage",
          name: "RoleOrdersManage",
          component: loadView("views/role/orders/manage/OrdersManage"),
        },
        {
          path: "rounds/id/:roundId?",
          name: "RoleOrdersRounds",
          component: loadView("views/role/orders/rounds/OrdersRounds"),
        },
      ],
    },
    {
      // Ruolo operativo
      path: "/role/:id?",
      redirect: "/role/:id/dashboard",
      name: "Role",
      component: loadView("containers/UiContainer"),
      // component: loadView("containers/RoleContainer"),
      meta: {
        auth: true,
      },
      children: [
        {
          //Dashboard
          path: "dashboard",
          name: "RoleDashboard",
          component: loadView("views/role/Dashboard"),
        },
        {
          //vetrine utente
          path: "data",
          name: "RoleDataContacts",
          redirect: "/role/:id/data/role",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              //Ruolo e dati amministrativi
              path: "role",
              // name: "Ruolo e dati amministrativi",
              name: "RoleDataRole",
              component: loadView("views/role/data/Role"),
            },
            {
              //Info profilo e foto
              path: "profile",
              // name: "Info profilo e foto",
              name: "RoleDataProfile",
              component: loadView("views/role/data/Profile"),
            },
            {
              //Lista clienti
              path: "customers",
              // name: "Lista clienti",
              name: "RoleDataCustomers",
              component: loadView("views/uc"),
            },
          ],
        },
        {
          //vetrine utente
          path: "products",
          name: "RoleProducts",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              //Profilo utente
              path: "showcase",
              name: "RoleProductsShowcase",
              component: loadView("views/role/products/Showcase"),
            },
            {
              //Profilo utente
              path: "settings",
              name: "RoleProductsSettings",
              component: loadView("views/role/products/Settings"),
            },
          ],
        },
        {
          //vetrine utente
          path: "logistics",
          name: "Logistica",
          redirect: "/role/logistics/connections",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              //Profilo utente
              path: "connections",
              // name: "Connessioni",
              name: "RoleLogisticsConnections",
              component: loadView("views/role/logistics/Connections"),
            },
            {
              //Profilo utente
              path: "planning",
              name: "RoleLogisticsPlanning",
              // name: "Pianificazioni",
              component: loadView("views/role/logistics/Planning"),
            },
            // {
            //   //Profilo utente
            //   path: "deliveries",
            //   // name: "Configurazione consegne",
            //   name: "RoleLogisticsDelivery",
            //   component: loadView("views/uc"),
            // },
            // {
            //   //Profilo utente
            //   path: "operations",
            //   // name: "Configurazione operativa",
            //   name: "RoleLogisticsDeliveryOpConfiguration",
            //   component: loadView("views/uc"),
            // },
          ],
        },
        {
          //vetrine utente
          path: "orders",
          name: "Ordini",
          redirect: "/role/orders/current",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              //Profilo utente
              path: "current",
              // name: "In corso",
              name: "RoleOrdersInProgress",
              component: loadView("views/role/orders/OrdersCurrent"),
            },
            {
              //Profilo utente
              path: "next",
              // name: "In arrivo",
              name: "RoleOrdersUpcoming",
              component: loadView("views/role/orders/OrdersNext"),
            },
            {
              //Profilo utente
              path: "archive",
              // name: "Archivio",
              name: "RoleOrdersArchive",
              component: loadView("views/role/orders/OrdersArchive"),
            },
          ],
        },
        {
          //vetrine utente
          path: "finance",
          name: "Contabilità",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "invoices-out",
              name: "RoleFinanceInvoicesOut",
              component: loadView("views/role/finance/InvoicesOut"),
            },
            {
              path: "invoices-in",
              name: "RoleFinanceInvoicesIn",
              component: loadView("views/role/finance/InvoicesIn"),
            },
            {
              path: "settings",
              name: "RoleFinanceSettings",
              component: loadView("views/role/finance/InvoicesSettings"),
            },
          ],
        },
        {
          //vetrine utente
          path: "export",
          name: "RoleExports",
          redirect: "/role/export/tax",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              //Profilo utente
              path: "tax",
              name: "Tabelle IVA",
              component: loadView("views/uc"),
            },
            {
              //Profilo utente
              path: "invoices",
              name: "Fatture",
              component: loadView("views/uc"),
            },
            {
              //Profilo utente
              path: "products",
              name: "Prodotti",
              component: loadView("views/uc"),
            },
            {
              //Profilo utente
              path: "orders",
              name: "Ordini",
              component: loadView("views/uc"),
            },
          ],
        },
      ],
    },
    {
      path: "/loonity",
      redirect: "/loonity/dashboard",
      name: "Nome Ruolo",
      component: loadView("containers/LoonityContainer"),
      meta: {
        auth: true,
      },
      children: [
        {
          path: "dashboard",
          name: "Dashboard",
          component: loadView("views/uc"),
        },
        {
          path: "geo",
          redirect: "/loonity/geo/timezone",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "timezone",
              name: "Timezone",
              component: loadView("views/rloonity/geo/Timezone"),
            },
            {
              path: "language",
              name: "Language",
              component: loadView("views/rloonity/geo/Language"),
            },
            {
              path: "currency",
              name: "Currency",
              component: loadView("views/rloonity/geo/Currency"),
            },
            {
              path: "country",
              name: "Country",
              component: loadView("views/rloonity/geo/Country"),
            },
          ],
        },
        {
          path: "products",
          redirect: "/loonity/products/products",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "products",
              name: "Prodotti",
              component: loadView("views/rloonity/Products"),
            },
          ],
        },
      ],
    },
    {
      path: "/public",
      redirect: "/loonity/dashboard",
      // name: 'Nome Ruolo',
      component: loadView("containers/PublicContainer"),
      meta: {
        auth: false,
      },
      children: [
        {
          path: "login",
          name: "Login",
          component: loadView("views/public/Login"),
        },
        {
          path: "registration",
          name: "Registration",
          component: loadView("views/public/Registration"),
        },
        {
          path: "contact",
          name: "Contact",
          component: loadView("views/uc"),
        },
        {
          path: "passwordreset",
          name: "PasswordReset",
          component: loadView("views/public/PasswordReset"),
        },
        {
          path: "/login/social/:type",
          name: "login-social-provider",
          component: loadView("views/public/SocialLoginHandler"),
          meta: {
            auth: undefined,
          },
        },
      ],
    },
    {
      path: "/wip",
      component: loadView("containers/SimpleContainer"),
      meta: {
        auth: undefined,
      },
      children: [
        {
          path: "product",
          name: "Product",
          component: loadView("views/wip/Product"),
        },
      ],
    },
  ],
});

export default Vue.router;
