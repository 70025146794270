import { DataManager, Query, Predicate } from "@syncfusion/ej2-data";
import { apiUrl } from "../http";
import DrfAdaptor from "../http/EJ2_adapter";
import DrfAdaptorNew from "../http/DrfAdaptorNew";

const paths = {
  //Auth
  auth_role: "/api/auth/roles/",
  auth_role_cdr: "/api/auth/roles/cdr/",
  auth_frole: "/api/auth/familyroles/",
  auth_faddress: "/api/auth/family/{0}/address/",
  auth_fwallet: "/api/auth/family/{0}/wallet/",
  auth_role_connection: "/api/auth/roles/{0}/connection/",
  auth_role_image: "/api/auth/roles/{0}/image/",
  auth_family: "/api/auth/family/",
  auth_family_cdr: "/api/auth/family/cdr/",
  auth_family_connection: "/api/auth/family/{0}/connection/",
  auth_markets: "/api/auth/markets/",
  auth_market_prices: "/api/auth/markets/{0}/prices/",
  auth_orders: "/api/auth/orders/",
  auth_orders_details: "/api/auth/orders/details/",
  auth_email: "/api/auth/email/",
  auth_social: "/api/auth/social/",

  role_family_dash_orders: "/api/family/{0}/dash/orders/",
  role_family_dash_pp: "/api/family/{0}/dash/pp/",

  // Role
  role_planningclose: "/api/role/{0}/planning/close/",
  role_planningcalendar: "/api/role/{0}/planning/calendar/{1}/{2}/",


  // Geo
  geo_tax: "/api/geo/tax_rate/",
  geo_country: "/api/geo/country/",
  geo_postalcode: "/api/geo/postal_code/",

  // Structure
  structure_ptype: "/api/structure/macrocategory/",
  structure_ltype: "/api/structure/typeproduct/",
  structure_ageclass: "/api/structure/ageclass/",
  structure_category_browser: "/api/structure/category_browser/",
  structure_production: "/api/structure/production/",
  structure_certification: "/api/structure/certification/",
  structure_certification_macro: "/api/structure/certification_macro/",

  // Producer
  role_producer_connections: "/api/producer/{0}/connections/",
  role_producer_products: "/api/producer/{0}/products/",
  role_producer_products_NEW: "/api/producer/{0}/prods/",
  role_producer_products_cdr: "/api/producer/{0}/products/cdr/",
  role_producer_products_lots: "/api/producer/{0}/productlots/",
  role_producer_products_lots_NEW: "/api/producer/{0}/lots/",
  role_producer_products_lots_cdr: "/api/producer/{0}/productlots/cdr/",
  role_producer_categorytree: "/api/producer/{0}/categorytree/",
  role_producer_typeproduct: "/api/producer/{0}/typeproduct/",

  role_producer_orders_ddmp: "/api/producer/{0}/orders/ddmp/",
  role_producer_orders_detail: "/api/producer/{0}/orders/detail/",
  role_producer_orders_pdetail: "/api/producer/{0}/orders/pdetail/",
  role_producer_orders_plist: "/api/producer/{0}/orders/plist/",

  role_producer_dash_orders: "/api/producer/{0}/dash/orders/",
  role_producer_dash_pp: "/api/producer/{0}/dash/pp/",

  // Market
  role_market: "/api/market/{0}/settings/",
  role_marketproducer_connections: "/api/market/{0}/connections/producer/",
  role_marketproducer: "/api/market/{0}/producer/",
  role_marketisland_connections: "/api/market/{0}/connections/island/",
  role_marketisland: "/api/market/{0}/island/",
  role_market_planningdata: "/api/market/{0}/planning/data/",
  role_market_planningwh: "/api/market/{0}/planning/wh/",
  role_market_planningtruck: "/api/market/{0}/planning/truck/",
  role_market_ppm: "/api/market/{0}/ppm/",
  role_market_ordersarchive: "/api/market/{0}/orders/archive",
  role_market_ordersnext: "/api/market/{0}/orders/next",
  role_market_orderscurrent: "/api/market/{0}/orders/{1}/current/",

  role_market_orders_tp: "/api/market/{0}/orders/{1}/tp/",
  role_market_orderswh: "/api/market/{0}/orders/{1}/wh/",
  role_market_orders_ddmp: "/api/market/{0}/orders/{1}/ddmp/",
  role_market_orders_ddmi: "/api/market/{0}/orders/{1}/ddmi/",
  role_market_orders_detail: "/api/market/{0}/orders/{1}/detail/",
  // role_market_orders_detail: "/api/market/{0}/orders/detail",
  role_market_orders_pdetail: "/api/market/{0}/orders/{1}/pdetail/",
  role_market_orders_odetail: "/api/market/{0}/orders/{1}/odetail/",
  role_market_orders_plist: "/api/market/{0}/orders/{1}/plist/",
  role_market_orders_mlist: "/api/market/{0}/orders/{1}/mlist/",
  role_market_orders_incident: "/api/market/{0}/orders/{1}/incident/",
  role_market_orders_round: "/api/market/{0}/orders/round/",

  role_market_dash_orders: "/api/market/{0}/dash/orders/",
  role_market_dash_pp: "/api/market/{0}/dash/pp/",

  

  role_orders_incident: "/api/order/{0}/incident/",
  role_orders_incident_details: "/api/order/{0}/incident/{1}/details",
  role_orders_manager: "/api/order/manager/",
  role_orders_incident_manager: "/api/order/incident/manager/",
  role_orders_incident_multimanager: "/api/order/incident/multimanager/",

  role_order_pw: "/api/order/pw/",
  role_order_cancellation: "/api/order/cancellation/",
  role_order_incident: "/api/order/incident/",
  role_order_fincident: "/api/order/fincident/",

  role_order_family: "/api/order/{0}/forder/",
  role_order_family_details: "/api/order/{0}/forder/{1}/details/",

  role_order_role: "/api/order/{0}/rorder/",
  role_order_role_details: "/api/order/{0}/rorder/{1}/details/",

  // invoices
  role_invoices_emitted: "/api/invoice/{0}/emitted/",
  role_invoices_toemit: "/api/invoice/{0}/toemit/",
  role_invoices_toemit_details: "/api/invoice/{0}/toemit/{1}/details/",
  role_invoices_received: "/api/invoice/{0}/received/",

  // Island
  role_island_connections: "/api/island/{0}/connections/",

  role_island_orders_ddmi: "/api/island/{0}/orders/ddmi/",
  role_island_orders_detail: "/api/island/{0}/orders/detail/",
  role_island_orders_mlist: "/api/island/{0}/orders/mlist/",

  role_island_dash_orders: "/api/island/{0}/dash/orders/",

  // Cart
  cart_usercarts: "/api/cart/user/",
  cart_usercarts_detail: "/api/cart/user/detail/{0}/",

  family_markets: "/api/family/{0}/market/",
  family_market_products: "/api/family/{0}/market/{1}/prods/",
  family_market_variants: "/api/family/{0}/market/{1}/variants/",
  family_market_producers: "/api/family/{0}/market/{1}/producers/",
  family_market_categories: "/api/family/{0}/market/{1}/categories/",
  family_market_deliveries: "/api/family/{0}/market/{1}/deliveries/",

  family_market_carts: "/api/family/{0}/market/{1}/carts/",
  family_market_current_cart: "/api/family/{0}/market/{1}/currentcart/",

  family_orders: "/api/family/{0}/order/",
  family_order_details: "/api/family/{0}/order/{1}/details/",
};

String.prototype.format = function (p) {
  var s = this,
    r = function (v, i) {
      s = s.replace(new RegExp("\\{" + i + "\\}", "g"), v);
    };
  p.forEach(r);
  return s;
};

function ApiCallOk(e) {
  console.log("OK");
  console.log(e.result);
}

function ApiCallErr(reason) {
  console.log("ERROR");
  let errors = JSON.parse(reason[0].error.response);
  console.log(errors);
}

function ApiCall(
  ds,
  query = new Query(),
  OkCallback = ApiCallOk,
  ErrCallback = ApiCallErr
) {
  // console.log("ds", ds)
  // console.log("query", query)
  // console.log("OkCallback", OkCallback)
  // console.log("ErrCallback", ErrCallback)
  return ds.executeQuery(query).then(
    (e) => {
      return OkCallback(e);
    },
    (e) => {
      return ErrCallback(e);
    }
  );
}

function GetDataManager(name, params = [], id = "") {
  // console.log(params)
  let path = paths[name].format(params);
  // console.log(path)
  let url = apiUrl(`${path}${id}`);

  // console.log(url)
  return new DataManager({
    url: url,
    adaptor: new DrfAdaptor(),
    crossDomain: true,
  });
}

function GetDataManagerNew(name, params = [], id = "") {
  const url = endPoint(name, params, id);
  return new DataManager({
    url: url,
    adaptor: new DrfAdaptorNew(),
    crossDomain: true,
  });
}

const endPoint = (name, params = [], id = "", full = true) => {
  let path = paths[name].format(params);
  if (id) {
    path += `${id}/`;
  }
  return full ? apiUrl(`${path}`) : `${path}`;
};

export {
  endPoint,
  ApiCall,
  GetDataManager,
  GetDataManagerNew,
  Query,
  Predicate,
};
